<template>
    <Setup-Step
        @yes="f => addNew()"
        @no="next"
        @restart="stage = 0"
        :loadingMsg="loadingMsg"
        :settings="settings"
        :showRestart="stage != 0"
        :showSkip="stage == 0"
        :showYesNo="stage == 0"
        step="locations"
        title="Locations">
        <template v-slot:body>
            <v-tabs-items v-model="stage" touchless>
                <v-tab-item>
                    <BT-List-Endless
                        :canSearch="false"
                        :loadingMsg.sync="loadingMsg"
                        maxHeight="60vh"
                        navigation="locations"
                        :refreshToggle="refreshList">
                        <template v-slot="{ item }">
                            <v-list-item-content>
                                <v-list-item-title>{{ item.locationName }}</v-list-item-title>
                                <v-list-item-subtitle>{{ item | toLocationLine }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-btn small text @click="addNew(item)">change</v-btn>
                            </v-list-item-action>
                        </template>
                    </BT-List-Endless>
                    <p class="my-4">Would you like to add another location?</p>
                </v-tab-item>

                <v-tab-item>
                    <BT-Field-String
                        label="Location Name"
                        v-model="newItem.locationName"
                        isEditing />
                    <BT-Btn 
                        buttonClass="primary mx-1"
                        inline
                        label="Next"
                        @click="stage += 1" />
                </v-tab-item>

                <v-tab-item>

                    <BT-Field-String
                        v-model="newItem.addressLineOne"
                        label="Address Line One"
                        isEditing />

                    <BT-Field-String
                        v-model="newItem.streetNumber"
                        label="Street Number"
                        isEditing />

                    <BT-Field-String
                        v-model="newItem.streetName"
                        label="Street Name"
                        isEditing />
                    
                    <BT-Field-String
                        v-model="newItem.suburb"
                        label="Suburb"
                        isEditing />

                    <BT-Field-String
                        v-model="newItem.state"
                        label="State"
                        isEditing />
                    
                    <BT-Field-String
                        v-model="newItem.postcode"
                        label="Postcode"
                        isEditing />

                    <BT-Btn
                        buttonClass="primary mx-1"
                        inline
                        label="Next"
                        @click="findCoordinates" />
                        
                </v-tab-item>
                <v-tab-item>
                    
                    <BT-Field-Number
                        v-model="newItem.lat"
                        label="Latitude"
                        isEditing />

                    <BT-Field-Number
                        v-model="newItem.lng"
                        label="Longitude"
                        isEditing />

                    <GmapMap
                        ref="locMap"
                        :center="center"
                        :zoom="7"
                            style="width: 100%; height: 50vh;"
                            :options="{
                                zoomControl: true,
                                mapTypeControl: false,
                                scaleControl: false,
                                streetViewControl: false,
                                rotateControl: false,
                                fullscreenControl: false,
                                disableDefaultUi: false }">                    
                                <gmap-marker v-if="newItem != null && newItem.lat != null && newItem.lng != null" :position="{ lat: newItem.lat, lng: newItem.lng }" />
                    </GmapMap>

                    <BT-Btn
                        buttonClass="primary mx-1 mt-4"
                        inline
                        label="Next"
                        @click="save" />

                </v-tab-item>

                <v-tab-item>
                    <p>Lastly, do you have any notes for drivers to see when delivering to this location?</p>
                    
                    <BT-Entity
                        :itemValue="newItem.id"
                        navigation="delivery-locations"
                        single>
                        <template v-slot="{ item, save }">
                            <BT-Field-Text-Area
                                label="Delivery Instructions"
                                v-model="item.driverInstructions"
                                isEditing />

                            <BT-Btn
                                buttonClass="primary mx-1"
                                inline
                                label="Done"
                                @click="end(save)" />
                        </template>
                    </BT-Entity>
                </v-tab-item>
            </v-tabs-items>
        </template>
    </Setup-Step>
</template>

<script>
import { getGoogleMapsAPI } from 'gmap-vue';
import { getLocationLine } from '~helpers';

export default {
    name: 'Location-Setup',
    components: {
        SetupStep: () => import('~home/setup/Setup-Step.vue'),
    },
    data() {
        return {
            center: { lat: -38, lng: 144 },
            loadingMsg: null,
            newItem: {},
            refreshList: false,
            stage: 0
        }
    },
    computed: {
        google: getGoogleMapsAPI
    },
    props: {
        settings: { //{ currentIndex: 0, stepperPlan: [], currentStep: 0 }
            type: Object,
            default: null
        },
    },
    methods: {
        addNew(j) {
            this.newItem = j || { lat: null, lng: null };
            this.stage += 1;
        },
        async end(saveFunc) {
            await saveFunc();

            this.stage = 0;
            this.refreshList = !this.refreshList;
        },
        async findCoordinates() {
            this.loadingMsg = 'Loading Coordinates';
            this.stage += 1;

            await this.$gmapApiPromiseLazy();
            var address = getLocationLine(this.newItem, true);
            var request = { address: address };
            
            var geo = new this.google.maps.Geocoder();
            
            
            try {
                var res = await geo.geocode(request);
                res = res.results;
                var selected = null;

                if (res.length > 1) {
                    selected = await this.$selectItemFrom({
                        items: res,
                        itemText: 'formatted_address',
                        required: true
                    });
                }
                else if (res.length == 1) {
                    selected = res[0];
                }
                
                if (selected != null) {
                    this.newItem.lat = selected.geometry.location.lat();
                    this.newItem.lng = selected.geometry.location.lng();
                }
            }
            finally {
                this.loadingMsg = null;
                
                
            }
        },
        next(nextFunc) {
            nextFunc();
        },
        async save() {
            try {
                this.loadingMsg = 'Saving';
                var res = null;

                if (this.newItem.id == null) {
                    res = await this.$BlitzIt.store.post('locations', this.newItem);
                    
                    //wait a couple seconds
                    await this.twiddleThumbs(2000);
                }
                else {
                    res = await this.$BlitzIt.store.patch('locations', this.newItem);
                }
                
                this.newItem.id = res.id;
                this.newItem.rowVersion = res.rowVersion;

                this.stage += 1;
            }
            finally {
                this.loadingMsg = null;
            }
            
        }
    }
}
</script>